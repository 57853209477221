import { useEffect } from 'react';
import { BASE_URL } from '../../constants';
const AjaxLoader = (props)=>{
    useEffect(()=>{
        //document.body.className += !props.isAuthenticated ? " login-page" : '';
    })
	return (
        <>
        {props.show && 
        <>
            <div className="lodingbg"></div>
            <div className="wrapper">
                <img src={BASE_URL+"/img/loader-logo.svg"} width="100"/>
                <div className="loading-text"> 
                    <div>
                        <span className="dot-one"> .</span>
                        <span className="dot-two"> .</span>
                        <span className="dot-three"> .</span>
                    </div>
                </div>
            </div>
        </>}
        </>
	)	
}
export default AjaxLoader;