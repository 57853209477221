import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router,Route,Navigate,Routes ,Link, useLocation } from 'react-router-dom';
import Layout from './components/Layouts';
import routes from './route';
import { CTX } from './context';
import AOS from "aos";
import "aos/dist/aos.css";

const App = ()=> {
  const objContext = useContext(CTX);
  const { isPageLoad,setPageLoad } = objContext;
  const isAuthenticated = false;//useSelector(state => state.authentication.loggedIn);
   useEffect(() => {
        AOS.init();
        AOS.refresh();
    },[])
  // basename={'/turkmengala'}
  return (
    <Router>
    <Layout isAuthenticated={isAuthenticated} loaderShow={isPageLoad}>
      <Routes>         
        {routes.map((route, i) => (
           <Route exact key={i}
                  path={route.path}
                  element={<route.component routes={route.routes} />}
          />
        ))}
      </Routes>
      
    </Layout>
    </Router>
  );
}

export default App;