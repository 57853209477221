const API_BASE_URL = `https://turkmengala.com/admin/api/`;

export const API_URL = {
    HOME_API : `${API_BASE_URL}get_dashboard`,
    CONTACT_US_FORM_API : `${API_BASE_URL}save_enquiry_form`,    

    CATEGORY_API : `${API_BASE_URL}get_categories`,
    COMPANY_API : `${API_BASE_URL}get_companies`,
    PARTNER_API : `${API_BASE_URL}get_partners`,
    JOBS_API : `${API_BASE_URL}get_recruitments`,
    SUB_CATEGORY_API : `${API_BASE_URL}get_category_details`,
    ITEM_DETAILS_API : `${API_BASE_URL}get_item_details`,

    JOB_APPLY_API : `${API_BASE_URL}save_applicant_form`
}