import React, { useState, useEffect } from 'react';

const CTX = React.createContext();

export { CTX };

export default function Store(props) {
  
  const [isPageLoad, setIsPageLoad] = useState(false);
  const [footerMenu, setFooterMenu] = useState({});
  const [activeMenu, setActiveMenu] = useState('home');

  useEffect(() => {
  }, []);

  const setPageLoad = (st = false) => {
    setIsPageLoad(st);
  };
  const setFooterLink = (menu) => {
    setFooterMenu(menu);
  };
  const setMenu = (menu) => {
    setActiveMenu(menu);
  };

  return (
    <CTX.Provider value={{
      isPageLoad,setPageLoad,setFooterLink,footerMenu,setMenu,activeMenu
    }}>
      {props.children}
    </CTX.Provider>
  );
}
