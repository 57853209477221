import React, { useEffect, useState, useContext, useRef } from "react"
import { Link } from 'react-router-dom';
//import ReactPaginate from 'react-paginate';
import { capitalize,callService } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_Title,BASE_URL } from '../../constants';
import { CTX } from '../../context';
import AOS from "aos";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const WhoWeAre = (props) => {
    let title = "";
    const dataFetchedRef = useRef(false);
    const [items, setItems] = useState([]);
    const [url, setUrl] = useState('');
    const [banner, setBanner] = useState('');
    //const [pagination,setPagination] = useState({pageCount:0,initialPage:0});

    const objContext = useContext(CTX);
    const { setPageLoad,setFooterLink } = objContext;
    
    useEffect(()=>{
        const asyncFn = async()=>{
            await fetchData();
        };
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        asyncFn();
    },[!dataFetchedRef.current])//props.match.params.type

    const fetchData = async(queryString = {}) =>{
        setPageLoad(true);
        let url = `${API_URL.COMPANY_API}` + '';
        const response = await callService(url,'POST',{});
        //console.log(response,"api user list get ");
        if(typeof response !== undefined && typeof response.success !== undefined && response.success){
        //if(response.success !== undefined && response.success){
            //const {items,Pagination} = response.data;
            setItems(response.data.list);
            setUrl(response.extra.url);
            if(response.data?.banner){
              setBanner(response.data.banner);
            }
            setFooterLink({'energy':response.data.footer_energy_category,"infra":response.data.footer_infra_category})
            //setPagination({...pagination,pageCount:Pagination.last_page,initialPage:(Pagination.current_page-1)})
        }
        setPageLoad(false);
    }
    
    return (
      <>
     <section className="banner page-banner"> 
     {banner.length > 0 ? 
        <img src={url+'/storage/'+banner} width="100%"/> 
        :
        <img src={BASE_URL+"/img/Business-with-us.jpg"} width="100%"/> 
        }
     </section>
<section className="py-5 mb-lg-4">
  <div className="container">
    <div className="row">
      <div className="col-lg-11 m-auto text-center ">
        <div className="title pb-1 pt-2 fw-normal text-primary" data-aos="fade-up" >Who we are</div>
        <p className="pt-3 pb-0 text-center " data-aos="fade-up">Turkmen Gala is a local company that has supported the growth of Turkmenistan’s energy sector for more than 12 years. We are proud to provide proficient solutions and expertise throughout the region. Over the years Turkmen Gala has shaped a business philosophy where partnerships, service quality, consistency, and above all customer satisfaction are paramount.</p>
        <p data-aos="fade-up">Turkmen Gala holds a track record on Innovation, Performance, Reliability, and Total Customer Satisfaction.</p>
        <p data-aos="fade-up">Our Approach Has Been to Build on The Growth Dynamics of The Region and To Combine International Management Disciplines, Thereby Distinguishing It as an Organization Possessing Regional Expertise, Enhanced by Local Knowledge. It Is the Hard Work, Commitment, And Dedication of Our People That Has Resulted in Consistent Growth and Success. One of the Key Objectives of Our Corporate Strategy Is to Attract, Maintain and Develop Talents.</p>
      </div>
    </div>
  </div>
</section>
<section className="business-units p-0 vision-mission-container">
   <div className="container">
    <div className="row">
      <div className="container-fluid">
      <div className="row">
       <div className="col-lg-6 px-0 our-left our-about" data-aos="fade-up">
        <div className="our-about-box our-vision">
       <h3 className="text-uppercase pb-2">  
      Our vision
      </h3>
      To continuously execute our potential while relentlessly evolving by creating value and growing our market share through Excellence.
       </div> 
      </div>

       <div className="col-lg-6 px-0  our-right our-about" data-aos="fade-up">
        <div className="our-about-box">
       <h3 className="text-uppercase pb-2">   
      Our mission
      </h3>
      To be the professional and reputable partner of choice for delivering reliable solutions, with cutting edge Technologies while equipped with a proﬁcient team proactively through Operational excellence.
       </div> 
      </div>
      </div>
      </div>
    </div>
  </div>
</section>
<section className="business-units p-0 ethos-container">
   <div className="container">
    <div className="row">
      <section className="out-ethos">
        <div className="container text-center" data-aos="fade-up">
           <h3 className="text-uppercase pb-2">Our ethos</h3>
      Our ethos is simple. We are one family at Turkmen Gala. We strongly believe that by respecting and investing in our Team development, our business effortlessly ﬂourishes. Our people are our principal resource which is recognized and valued. Annually, there are various incentives & recognition programs that acknowledge their invaluable contribution and commitment to our Family to express gratitude and commitment to them.
        </div>
      </section>
    </div>
  </div>
</section>
<section className="ims-certificate">
  <div className="container">
    <div className="row">
      <div className="col-lg-10 m-auto text-center " >
        <div className="title pb-0 pt-2 fw-normal text-primary" data-aos="fade-up">Our core values </div>
        <p className="pt-3 pb-5 text-center " data-aos="fade-up"> “TURKMEN GALA” HJ is a Turkmen certiﬁed company registered under license No. 21010027 Our specializations are in the oil & gas (onshore and offshore), industrial sector and energy industries: </p>
      </div>
    </div>
    <div className="row gy-lg-5 gy-4 text-center justify-content-center">
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="our-core-values">
          <div className="d-flex justify-content-center"><div className="our-core-values-iocn" data-aos="zoom-out-up"><img src={BASE_URL+"/img/our-core-values-iocn1.svg"}/> </div></div>
          <div className="our-core-values-title">Family</div>
          <p> FAMILY comes first, In Turkmen Gala is - our customers, partners and our most valuable asset - our team </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="our-core-values">
          <div className="d-flex justify-content-center"><div className="our-core-values-iocn" data-aos="zoom-out-up"> <img src={BASE_URL+"/img/our-core-values-iocn2.svg"}/> </div></div>
          <div className="our-core-values-title">Growth</div>
          <p> Nothing is Constant. By embracing change, we GROW, LEARN AND DRIVE the business towards an assured future. </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="our-core-values">
          <div className="d-flex justify-content-center"><div className="our-core-values-iocn" data-aos="zoom-out-up"> <img src={BASE_URL+"/img/our-core-values-iocn3.svg"}/> </div></div>
          <div className="our-core-values-title">Integrity</div>
          <p> We uphold the highest ETHICAL principles and Actively foster TRUST & RESPECT </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="our-core-values">
          <div className="d-flex justify-content-center"><div className="our-core-values-iocn" data-aos="zoom-out-up"> <img src={BASE_URL+"/img/our-core-values-iocn4.svg"}/> </div></div>
          <div className="our-core-values-title">Quality</div>
          <p> We provide unparallel COST-EFFECTIVE SOLUTIONS to our ESTEEMED Clientele. </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="our-core-values">
          <div className="d-flex justify-content-center"><div className="our-core-values-iocn" data-aos="zoom-out-up"> <img src={BASE_URL+"/img/our-core-values-iocn5.svg"}/> </div></div>
          <div className="our-core-values-title">Safety</div>
          <p> For our Corps & Community, Safety is paramount - without it nothing else Matters.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="our-core-values">
          <div className="d-flex justify-content-center"><div className="our-core-values-iocn" data-aos="zoom-out-up"> <img src={BASE_URL+"/img/our-core-values-iocn6.svg"}/> </div></div>
          <div className="our-core-values-title">Innovation</div>
          <p> As empowered & DYNAMIC Entrepreneurs, our team OFFERS INNOVATIVE leading solutions through delivering best “know how”. </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <div className="our-core-values">
          <div className="d-flex justify-content-center"><div className="our-core-values-iocn" data-aos="zoom-out-up"> <img src={BASE_URL+"/img/our-core-values-iocn7.svg"}/> </div></div>
          <div className="our-core-values-title">Effectiveness</div>
          <p> We are HUMBLE, HARDWORKING, INGENIOUS and Competent which means that our commitments will be delivered. </p>
        </div>
      </div>
    </div>
    <div className="row pt-lg-5 pt-4">
      <div className="col-lg-10 m-auto text-center " data-aos="fade-up">
        <div className="title pb-0 pt-2 fw-normal text-primary">Company Registration & License </div>
        <p className="pt-3 pb-5 text-center " data-aos="fade-up"> “TURKMEN GALA” HJ is a Turkmen certiﬁed company registered under license No. 21010027 Our specializations are in the oil & gas (onshore and offshore), industrial sector and energy industries: </p>
      </div>
    </div>
    <div className="row  gy-4 justify-content-center text-center">
      {items.length > 0 ?
            items.map((item,key)=>{
                return (
                  <div key={key} className="col-lg-3 col-md-3 col-sm-4 col-6" data-aos="fade-up"><Zoom> <img src={url+'/storage/'+item.registration_license} className="img-fluid" alt={item.name} width="200"/></Zoom> </div>
                      );
            })
            :
          <div className="col-lg-3" data-aos="fade-up">
            <div className="service-box text-center" data-aos="fade-up"> 
                No Record Found. 
            </div>
          </div>}
    </div>
  </div>
</section>
    </>
  )
}

export default WhoWeAre