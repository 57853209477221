import React, { useEffect, useState, useContext, useRef } from "react"
import { Link } from 'react-router-dom';
//import ReactPaginate from 'react-paginate';
import { capitalize,callService } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_Title } from '../../constants';
import { CTX } from '../../context';
import AOS from "aos";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const BusinessWithUs = (props) => {
    let title = "";
    const dataFetchedRef = useRef(false);
    const [items, setItems] = useState([]);
    const [url, setUrl] = useState('');
    const [banner, setBanner] = useState('');
    //const [pagination,setPagination] = useState({pageCount:0,initialPage:0});

    const objContext = useContext(CTX);
    const { setPageLoad,setFooterLink,setMenu } = objContext;
    
    useEffect(()=>{
        const asyncFn = async()=>{
            await fetchData();
        };
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        asyncFn();
    },[!dataFetchedRef.current])//props.match.params.type

    const fetchData = async(queryString = {}) =>{
        setPageLoad(true);
        let url = `${API_URL.PARTNER_API}` + '';
        const response = await callService(url,'POST',{});
        if(typeof response !== undefined && typeof response.success !== undefined && response.success){
          setItems(response.data.list);
          setUrl(response.extra.url);
          if(response.data?.banner){
            setBanner(response.data.banner);
          }
          setFooterLink({'energy':response.data.footer_energy_category,"infra":response.data.footer_infra_category});
          //if(response.success !== undefined && response.success){
            //const {items,Pagination} = response.data;
            //setPagination({...pagination,pageCount:Pagination.last_page,initialPage:(Pagination.current_page-1)})
        }
        setPageLoad(false);
    }

   
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 9
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    return (
      <>
     <section className="banner page-banner"> 
     {banner.length > 0 ? 
        <img src={url+'/storage/'+banner} width="100%"/> 
        :
        <img src={"/img/Business-with-us.jpg"} width="100%"/> 
        }
     </section>
      <section className="py-5 mb-lg-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto text-center ">
              <div className="title pb-1 pt-2 fw-normal text-primary" data-aos="fade-up" >Business with us</div>
              <p  className="pt-3 pb-0 text-center fw-bold" data-aos="fade-up">Turkmen Gala HJ has a successful heritage of being a trusted partner to numerous clients, in Turkmenistan. Client satisfaction is at the centre of all the projects we deliver, thus establishing lasting relationships with our clients, partners, and shareholders. As we look forward to a futuristic, innovative approach, we will continue to explore opportunities that benefit our clients.</p>
              <p data-aos="fade-up"> <b>COUNTRY VALUE<br/>
                "Develop Locals" </b> </p>
              <p data-aos="fade-up"> We actively promote buying and sourcing our supplies and services within Turkmenistan, whenever this is possible. We love Turkmenistan and strive to support the community and local economy whenever the opportunity arises. </p>
            </div>
          </div>
        </div>
      </section>
      <section className="business-units">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 m-auto text-center ">
              <div className="title pb-1 pt-2 fw-normal text-primary" data-aos="fade-up" >Business Units</div>
              <p  className="pt-3 pb-0 text-center text-white" data-aos="fade-up">We always strive to create a well-aligned partner ecosystem that aims at generating mutually beneficial investment and expansion options. We apply expertise and transparency in all projects and services we deliver across our Business Units. </p>
              <div className="btn-bssines-units" data-aos="fade-up"> 
                <Link to={'/energy_sector'} className="btn btn-grey" onClick={()=>setMenu('energy_sector')}>Energy Sector</Link> <Link to={'/infra_sector'} className="btn btn-warning" onClick={()=>setMenu('infra_sector')}>Infra Sector</Link> </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 mb-lg-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto text-center ">
              <div className="title pb-1 pt-2 fw-normal text-primary" data-aos="fade-up">Our Partners</div>
              <p  className="pt-3 pb-0 text-center " data-aos="fade-up">Over a decade we have been successful in building a reputed clientele. Our team is dedicated toward maintaining a proactive approach that not only helps our clients, but also accelerates the growth of Turkmen Gala HJ. We are committed in ensuring that the standards of our business continue to nurture in an effective manner.</p>
            </div>
            <div className="row our-partners-logo mt-3 justify-content-center">
            {items.length > 0 ?
              <Carousel responsive={responsive} autoPlay={true} infinite={true} containerClass="carousel-container" itemClass="carousel-item-padding-40-px" autoPlaySpeed={2000}>
                {items.length > 0 &&
                items.map((item,key)=>{
                    return (
                      <div key={key} className="m-3 text-center"><img src={url+'/storage/'+item.logo} width="120" alt={item.name}/> </div>
                          );
                })}
              </Carousel>
              :
            <div className="col-lg-2" data-aos="fade-up">
              <div className="service-box text-center" data-aos="fade-up"> 
                  No Record Found. 
              </div>
            </div>}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BusinessWithUs