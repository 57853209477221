import React, { useEffect, useState, useContext, useRef } from "react"
import { Link } from 'react-router-dom';
//import ReactPaginate from 'react-paginate';
import { capitalize,callService } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_Title } from '../../constants';
import { CTX } from '../../context';
import AOS from "aos";

const initialState = {
                        name: '',
                        phone_number: '',
                        email: '',
                        recruitment_id: 0,
                        attachment: ''
                    };

const ContactUs = (props) => {
    let title = "";
    const [ submit, setSubmit] = useState(false);
    const [ successMsg, setSuccessMsg ] = useState('');
    const [ errorMsg, setErrorMsg ] = useState('');
    const dataFetchedRef = useRef(false);
    const [items, setItems] = useState([]);
    const [url, setUrl] = useState('');
    //const [pagination,setPagination] = useState({pageCount:0,initialPage:0});
    const [ formData, setFormData ] = useState(initialState);
    const { name,email,phone_number,recruitment_id,attachment } = formData;

    const objContext = useContext(CTX);
    const { setPageLoad,setFooterLink } = objContext;
    
    useEffect(()=>{
        const asyncFn = async()=>{
            await fetchData();
        };
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        asyncFn();
    },[!dataFetchedRef.current])//props.match.params.type

    const fetchData = async(queryString = {}) =>{
        setPageLoad(true);
        let url = `${API_URL.JOBS_API}` + '';
        const response = await callService(url,'POST',{});
        console.log(response,"api user list get ");
        if(typeof response !== undefined && typeof response.success !== undefined && response.success){
        //if(response.success !== undefined && response.success){
            //const {items,Pagination} = response.data;
            setItems(response.data.list);
            setUrl(response.extra.url);
            setFooterLink({'energy':response.data.footer_energy_category,"infra":response.data.footer_infra_category})
            //setPagination({...pagination,pageCount:Pagination.last_page,initialPage:(Pagination.current_page-1)})
        }
        setPageLoad(false);
    }

    const onFileChange = event => {
        const value = event.target.files[0];
        console.log(value," asd ");
        setFormData(inputs => ({ ...inputs, attachment: value }));                                   
    }
    const validateFrm = ()=> {
        let err = false;
        if(name.length == 0){
          err = true;
          setErrorMsg("Name field is mandatory.")
        }else if(email.length == 0){
          err = true;
          setErrorMsg("Email field is mandatory.")
        }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
          err = true;
          setErrorMsg("Provide valid email address.")
        }else if(phone_number.length == 0){
          err = true;
          setErrorMsg("Phone Number field is mandatory.")
        }else if(phone_number.length < 8){
          err = true;
          setErrorMsg("Phone Number should be minimum 8 digit.")
        }else if(recruitment_id == 0){
          err = true;
          setErrorMsg("Job title field is mandatory.")
        }else if(attachment == ''){
          err = true;
          setErrorMsg("Attachment field is mandatory.")
        }

        if(err){
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
        }

        return !err;
    }
    const handleChange = (e)=> {
        const { name, value } = e.target;

        setFormData(inputs => ({ ...inputs, [name]: value }));
    }
    const submitForm = async(e) =>{
      e.preventDefault();
      if(!submit){
        setErrorMsg('');
        setSuccessMsg('');
        if(validateFrm()){
            setSubmit(true);
            let url = `${API_URL.JOB_APPLY_API}` + '';
            let postData = {name,email,phone_number,recruitment_id,attachment:attachment};
            const response = await callService(url,'POST',postData,null,true);
            //console.log(response,"api user list get ");
            if(typeof response !== undefined && typeof response.success !== undefined && response.success){
            //if(response.success !== undefined && response.success){
                //console.log(response," reposnse ");
                setFormData(initialState);
                setSuccessMsg(response.message);
                setTimeout(() => {
                  setSuccessMsg('');
                }, 5000);
            }
            setSubmit(false);
        }
      }
    }
    
    return (
    <>
    <section className="banner page-contact p-0 mb-5" style={{height:100}}></section>
    <section className="banner contact-us-page">
    <div className="container">
    <div className="row">
      <div className="col-lg-5 pe-lg-5">
        <div className="title pb-1 pt-2 fw-normal text-primary" data-aos="fade-up">Work With Us</div>
        <p className="pt-3" data-aos="fade-up">Turkmen Gala HJ has a successful heritage of being a trusted partner to numerous clients, in Turkmenistan. </p>
      </div>
      <div className="col-lg-7">
        <div className="accordion" id="accordionExample" data-aos="fade-up">
          {items.length > 0 ?
            items.map((item,key)=>{
                return (
                <div key={key} className="accordion-item">
                  <h2 className="accordion-header">
                    <button className={key == 0 ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+key} aria-expanded={key == 0 ? "true" : "false"} aria-controls={"collapse"+key}>{item.name}</button>
                  </h2>
                  <div id={"collapse"+key} className={key == 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} data-bs-parent="#accordionExample">
                    <div className="accordion-body" dangerouslySetInnerHTML={{__html: item.description}}></div>
                  </div>
                </div>
                      );
            })
            :
            <></>
          }

        </div>
      </div>
    </div>
  </div>
</section>
<section className="sendcvmin">
  <div className="container">
    <div className="title pb-4 pt-2 fw-normal text-primary">Send CV</div>
    <div className="row">
      <div className="col-lg-5">
        <p className="pt-0">Turkmen Gala provides complete Inspection solutions for Energy sector. Each service we perform is carefully selected for each specific job. </p>
      </div>
      <div className="col-lg-7">
        <form className="sendcv" onSubmit={submitForm}>
          <div className="row gy-4">
            <div className="col-lg-6">
              <input type="text" className="form-control" placeholder="Name" name="name" onChange={handleChange} value={name}/>
            </div>
            <div className="col-lg-6">
              <input type="email" className="form-control" placeholder="Email Address" name="email" onChange={handleChange} value={email}/>
            </div>
            <div className="col-lg-6">
              <input type="number" className="form-control" placeholder="Phone number" name="phone_number" onChange={handleChange} value={phone_number}/>
            </div>
            <div className="col-lg-6">
              <select className="form-select form-control" aria-label="Default select example" name="recruitment_id" value={recruitment_id} onChange={handleChange}>
                <option value="0">Select job title</option>
                {items.length > 0 &&
                  items.map((item,key)=>{
                    return (<option value={item.id} key={key}>{item.name}</option>);
                  })}
              </select>
            </div>
            <div className="col-lg-12">
              <div className="attach-file">
                <input type="file" className="form-control" placeholder={"Attach file"} onChange={onFileChange} accept="application/pdf"/>
                <span>{attachment?.name && attachment?.name.length > 0 ? attachment.name : "Attach file"}</span> <img src="img/attachment.svg"/> </div>
            </div>
          </div>
          <button type="submit" className="btn  mt-4 btn-warning w-100" style={{marginTop: '-1px'}}>{submit && <div className="spinner-border spinner-border-sm"></div>} Submit</button>
          {successMsg.length > 0 && <p className="text-success">{successMsg}</p>}
          {errorMsg.length > 0 && <p className="text-danger">{errorMsg}</p>}
        </form>
      </div>
    </div>
  </div>
</section>
    </>
  )
}

export default ContactUs