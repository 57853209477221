import { Navigate } from 'react-router-dom'
import { API_TOKEN } from '../constants';

/**
 * Call service api 
 */
export const  callService = async(url,method='GET',params={},callBack=null,multipart=false,navigation=null) =>{
  let qry = [];
  let res,body;
  let headers = {  };
  if(multipart){
    //headers = { 'Content-Type' : 'multipart/form-data' };
    body = new FormData();
    // Assume "photo" is the name of the form field the server expects
    Object.keys(params).forEach(function(key) {
      body.append(`${key}`, params[key]);
    });
  }else{
    headers = { 'Content-Type' : 'application/x-www-form-urlencoded' };
    Object.keys(params).forEach(function(key) {
      qry.push(key+'='+params[key]);
    });
    body = qry.join('&');
  }
  /*if(store.getState().authentication && store.getState().authentication.loggedIn){
  }*/
  headers['X-Authorization'] = API_TOKEN;
  try {
    let serviceParams = {
      method: method, // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      //body:  body// body data type must match "Content-Type" header
    }
    if(method == 'POST' || method == 'PUT' || method == 'PATCH'){
      serviceParams['body'] = body;
    }
    //console.log("callservice nooday part",body);
    const response = await fetch(url,serviceParams);
    res = response ? response.status && response.status === 204 ? '' : await response.json() : '';
    
  } catch (error) {
    res = {success:false,error:error};
  }
  if(!res.success){
    console.log( ' Error ' , res );
    if(res.token_error != undefined && res.token_error){
      console.log(" i m in logout function ")
      //logout();
      return ;
    }
  }
  if(callBack!==null){
    callBack(res);
    return ;
  }else{
    return res;
  }
}


