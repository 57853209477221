import React,{ useEffect, useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../constants';
import { CTX } from '../../../context';

const Header = ()=>{
    /*const user = useSelector(state => state.authentication.user);
    const logout =()=>{
        authOption.signout();
        redirect('/login');
    }*/
    const objContext = useContext(CTX);
    const { activeMenu,setMenu } = objContext;
    const [collapsed , setCollapsed] = useState(false)
    useEffect(() => {
        let lastScrollPosition = 0;
        const navbar = document.querySelector('.navbar');
        window.addEventListener('scroll', function(e) {
            lastScrollPosition = window.scrollY;
            
            if (lastScrollPosition > 100)
              navbar.classList.add('navbar-dark');
            else
              navbar.classList.remove('navbar-dark');
          });
  
        /*if(alert.show){
            setTimeout(() => {
                dispatch(hideAlert());
              }, 5000)
        };*/
    },[])
    const toggleMenu = (e,url) => {
      setMenu(url);
      setCollapsed(false);
    }
	return (
		<header>
          <nav className="navbar navbar-expand-lg navbar-light fixed-top py-0">
            <div className="container"> 
            <Link className="navbar-brand" to="/"> 
              <img src={BASE_URL+"/img/logo.svg"} alt="" title="logo white" className="white-logo"/> 
              <img src={BASE_URL+"/img/logo-dark.svg"} alt="" title="logo" className="logo2"/> 
            </Link>
              <button className="navbar-toggler me-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" onClick={(e)=>setCollapsed(true)} aria-controls="navbarSupportedContent" aria-expanded={collapsed} aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.7222" stroke="white" strokeWidth="0.555556"></circle>
              <path d="M28.7246 13.3333L11.1103 13.3333" stroke="white" strokeWidth="2.22222" strokeLinecap="round"></path>
              <path d="M28.7246 20L11.1103 20" stroke="white" strokeWidth="2.22222" strokeLinecap="round"></path>
              <path d="M28.7246 26.6666L11.1103 26.6666" stroke="white" strokeWidth="2.22222" strokeLinecap="round"></path>
              </svg> </span> </button>
              <div className={collapsed ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
                <button className="navbar-toggler mobile-close-menu mb-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><img src={BASE_URL+"/img/close.png"} width="20"/></span> </button>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item ms-lg-4"> <Link className={activeMenu == 'energy_sector' ? "nav-link active" : "nav-link"} to="/energy_sector" onClick={(e)=>toggleMenu(e,'energy_sector')}>Energy Sector</Link> </li>
                  <li className="nav-item ms-lg-4"> <Link className={activeMenu == 'infra_sector' ? "nav-link active" : "nav-link"} onClick={(e)=>toggleMenu(e,'infra_sector')} to="/infra_sector">Infra Sector</Link> </li>
                  <li className="nav-item ms-lg-4"> <Link className={activeMenu == 'who_we_are' ? "nav-link active" : "nav-link"} onClick={(e)=>toggleMenu(e,'who_we_are')} to="/who_we_are">Who we are</Link> </li>
                  <li className="nav-item ms-lg-4"> <Link className={activeMenu == 'business_with_us' ? "nav-link active" : "nav-link"} onClick={(e)=>toggleMenu(e,'business_with_us')} to="/business_with_us">Business with us</Link> </li>
                  <li className="nav-item ms-lg-4"> <Link className={activeMenu == 'work_with_us' ? "nav-link active" : "nav-link"} onClick={(e)=>toggleMenu(e,'work_with_us')} to="/work_with_us">Connect Us</Link> </li>
                  {/*<li className="nav-item dropdown ms-lg-4">
                            <a className="nav-link dropdown-toggle global" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                           <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.51822 2.27237C2.13002 3.33255 1.20625 4.8916 0.776624 7H19.2234C18.7938 4.8916 17.87 3.33255 16.4818 2.27237C14.9611 1.11104 12.8201 0.5 10 0.5C7.17986 0.5 5.03886 1.11104 3.51822 2.27237ZM19.3849 8H0.615052C0.538617 8.62498 0.5 9.29146 0.5 10C0.5 10.7085 0.538617 11.375 0.615052 12H19.3849C19.4614 11.375 19.5 10.7085 19.5 10C19.5 9.29146 19.4614 8.62498 19.3849 8ZM19.2234 13H0.776625C1.20625 15.1084 2.13002 16.6675 3.51822 17.7276C5.03886 18.889 7.17986 19.5 10 19.5C12.8201 19.5 14.9611 18.889 16.4818 17.7276C17.87 16.6675 18.7937 15.1084 19.2234 13Z" stroke="#E5E1E6" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12.8327 10C12.8327 12.4887 12.465 14.9356 11.8806 16.7448C11.5874 17.6522 11.2485 18.3714 10.8941 18.8531C10.53 19.348 10.2215 19.5 9.99935 19.5C9.77721 19.5 9.46875 19.348 9.10459 18.8531C8.75018 18.3714 8.41126 17.6522 8.11811 16.7448C7.53365 14.9356 7.16602 12.4887 7.16602 10C7.16602 7.51128 7.53365 5.06437 8.11811 3.25523C8.41126 2.34784 8.75018 1.62857 9.10459 1.1469C9.46875 0.651994 9.77721 0.5 9.99935 0.5C10.2215 0.5 10.53 0.651994 10.8941 1.1469C11.2485 1.62857 11.5874 2.34784 11.8806 3.25523C12.465 5.06437 12.8327 7.51128 12.8327 10Z" stroke="#E5E1E6"/>
                              </svg>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                              <li><a className="dropdown-item" href="javascript:void(0);">English </a></li>
                            </ul>
                          </li>*/}
                </ul>
              </div>
            </div>
          </nav>
        </header>
	)	
}
export default Header;