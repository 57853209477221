import { Link } from 'react-router-dom';
const NotFound = (props)=>{
	return (
		<>
		<section className="service py-5 mb-lg-4"> </section>
		
		<section className="page_404">
	<div className="container">
		<div className="row">	
		<div className="col-sm-12 ">
		<div className="col-sm-12 col-sm-offset-1  text-center">
		<div className="four_zero_four_bg">
			<img src="img/Favicon.png" />
			<h1 className="text-center  align-middle ms-3 d-sm-inline-block">404</h1>
		
		
		</div>
		
		<div className="contant_box_404">
		<h3 className="h2">
		Look like you're lost
		</h3>
		
		<p>the page you are looking for not avaible!</p>
		
		<Link to="/" className="btn  mt-4 btn-warning">Go to Home</Link>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
		</>)
}
export default NotFound;