import { Link } from 'react-router-dom';
import NotFound from '../components/reusable/NotFound';
import Home from '../Pages/Home';
import Energy from '../Pages/Energy';
import Infra from '../Pages/Infra';
import WhoWeAre from '../Pages/WhoWeAre';
import BusinessWithUs from '../Pages/BusinessWithUs';
import ContactUs from '../Pages/ContactUs';
import Items from '../Pages/Items';
import ItemDetail from '../Pages/Items/details';

const routes = [
    {
        path: "/",
        component: Home,
        title: "",
        auth: false
    },
    {
        path: "/energy_sector",
        component: Energy,
        auth:false
    },
    {
        path: "/infra_sector",
        component: Infra,
        auth:false
    },
    {
        path: "/items/:slug",
        component: Items,
        auth:false
    },
    {
        path: "/details/:slug",
        component: ItemDetail,
        auth:false
    },
    {
        path: "/who_we_are",
        component: WhoWeAre,
        auth:false
    },
    {
        path: "/business_with_us",
        component: BusinessWithUs,
        auth:false
    },
    {
        path: "/work_with_us",
        component: ContactUs,
        auth:false
    },
    {
        path: "*",
        component: NotFound
    },
  ];

export default routes;