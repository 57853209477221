import React, { useEffect, useState, useContext, useRef } from "react"
import { Link,useParams } from 'react-router-dom';

//import ReactPaginate from 'react-paginate';
import { capitalize,callService } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_Title,CATEGORY_TYPE,BASE_URL } from '../../constants';
import { CTX } from '../../context';
import AOS from "aos";

const ItemDetail = (props) => {
    let title = "";
    //const search = useLocation();
    const {slug} = useParams();
    const dataFetchedRef = useRef(false);
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);
    const [url, setUrl] = useState('');
    const [banner, setBanner] = useState('');
    //const [pagination,setPagination] = useState({pageCount:0,initialPage:0});

    const objContext = useContext(CTX);
    const { setPageLoad,setFooterLink } = objContext;
    
    useEffect(()=>{
        const asyncFn = async()=>{
            await fetchData();
        };
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        asyncFn();
    },[slug])//props.match.params.type

    const fetchData = async(queryString = {}) =>{
        setPageLoad(true);
        let url = `${API_URL.ITEM_DETAILS_API}` + '';
        const response = await callService(url,'POST',{slug});
        //console.log(response,"api user list get ");
        if(typeof response !== undefined && typeof response.success !== undefined && response.success){
        //if(response.success !== undefined && response.success){
            //const {items,Pagination} = response.data;
            let resData = response.data.data;
            if(response.data.data?.images && response.data.data.images.length > 0){
              resData.images = JSON.parse(response.data.data.images);
            }else{
              resData.images = {};
            }
            setData(resData);
            setUrl(response.extra.url);
            if(response.data?.banner){
              setBanner(response.data.banner);
            }
            setFooterLink({'energy':response.data.footer_energy_category,"infra":response.data.footer_infra_category})
            //setPagination({...pagination,pageCount:Pagination.last_page,initialPage:(Pagination.current_page-1)})
        }
        setPageLoad(false);
    }
    
    return (
      <>
      <section className="banner page-banner"> 
        {banner.length > 0 ? 
        <img src={url+'/storage/'+banner} width="100%"/> 
        :
        <img src={BASE_URL+'/img/banner.jpg'} width="100%"/>
        }
      </section>
      <section className="service py-5 mb-lg-4">
        <div className="container">

          <div className="row">
            <div className="col-lg-11 m-auto">
              <div className="title pb-1 pt-2 text-center " data-aos="fade-up" >{data.name}</div>
              <p  className="pt-3 pb-4 text-center " data-aos="fade-up" dangerouslySetInnerHTML={{ __html: data.short_description }}></p>
            </div>
          </div>
          {data?.item_features && data?.item_features.length > 0 && data.item_features.map((item,key)=>{
            return (
          <div className="completionbox" key={key}>
            <div className="row">
              <div className="col-lg-2"><img src={url+"/storage/"+item.image} width="100%"/></div>
              <div className="col-lg-8">
                <h5 className="mb-0">{item.name}</h5>
                <p className="mb-0" dangerouslySetInnerHTML={{ __html: item.description.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></p>
              </div>
              <div className="col-lg-2 text-lg-end"> {item.pdf != null && <a href={url+"/storage/"+item.pdf} download target="_blank"> <img src={BASE_URL+"/img/pdf.png"} width="80"/></a>} </div>
            </div>
          </div>
          );
          })}
          {data?.video_url && 
          <div className="py-4">
             <iframe width="100%" height="451" src={data.video_url}></iframe> 
          </div>
          }
          <p className="pt-3 pb-4 text-center " data-aos="fade-up" dangerouslySetInnerHTML={{ __html: data.description }}></p>
          <div className="row pt-4">
            {data?.images && Object.keys(data.images).length > 0 && Object.values(data.images).map((img,key)=>{
              return (
                <div className="col-lg-3" key={key}>
                  <a href="javascript:void(0);"><img src={url+"/storage/"+img} width="100%"/></a>
                </div>
                );
            })}
          </div>

        </div>
      </section>
    </>
  )
}

export default ItemDetail