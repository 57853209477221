import { useEffect, useState } from 'react';
import Header from './Includes/Header';
import Footer from './Includes/Footer';
import AjaxLoader from '../reusable/AjaxLoader';

const Layout = (props)=>{
    const isAuthenticated = false;
    const isPageLoad = props.loaderShow ?? false;
    useEffect(() => {
        //document.body.className += !isAuthenticated ? " login-page" : '';
    },[])
	return (
		<div>
            <Header />
	        {props.children}
            <Footer />
            <AjaxLoader show={isPageLoad} />
	      </div>
	)	
}
export default Layout;