import React, { useEffect, useState, useContext, useRef } from "react"
import { Link } from 'react-router-dom';
//import ReactPaginate from 'react-paginate';
import { capitalize,callService } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_Title,CATEGORY_TYPE } from '../../constants';
import { CTX } from '../../context';
import AOS from "aos";

const Energy = (props) => {
    let title = "";
    const dataFetchedRef = useRef(false);
    const [items, setItems] = useState([]);
    const [url, setUrl] = useState('');
    const [banner, setBanner] = useState('');
    //const [pagination,setPagination] = useState({pageCount:0,initialPage:0});

    const objContext = useContext(CTX);
    const { setPageLoad,setFooterLink } = objContext;
    
    useEffect(()=>{
        const asyncFn = async()=>{
            await fetchData();
        };
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        asyncFn();
    },[!dataFetchedRef.current])//props.match.params.type

    const fetchData = async(queryString = {}) =>{
        setPageLoad(true);
        let url = `${API_URL.CATEGORY_API}` + '';
        const response = await callService(url,'POST',{type:CATEGORY_TYPE.ENERGY});
        //console.log(response,"api user list get ");
        if(typeof response !== undefined && typeof response.success !== undefined && response.success){
          //if(response.success !== undefined && response.success){
            //const {items,Pagination} = response.data;
            //console.log(response.data," page loaded ");
            //setPagination({...pagination,pageCount:Pagination.last_page,initialPage:(Pagination.current_page-1)})
            setItems(response.data.data);
            setUrl(response.extra.url);
            if(response.data?.banner){
              setBanner(response.data.banner);
            }
            setFooterLink({'energy':response.data.footer_energy_category,"infra":response.data.footer_infra_category})
        }
        setPageLoad(false);
    }
    
    return (
      <>
      <section className="banner page-banner"> 
        {banner.length > 0 ? 
        <img src={url+'/storage/'+banner} width="100%"/> 
        :
        <img src="img/banner.jpg" width="100%"/> 
        }
      </section>
      <section className="service py-5 mb-lg-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 m-auto">
              <div className="title pb-1 pt-2 text-center " data-aos="fade-up" >Energy Sector</div>
              <p  className="pt-3 pb-4 text-center " data-aos="fade-up"><strong>Turkmen Gala develops and delivers efficient, innovative, solutions for the energy sector.</strong><br/><br/>Turkmen Gala ES is an Turkmenistan local company that has successfully contributed the growth of Turkmenistan’s energy sector for more than a decade. </p>
            </div>
          </div>
          <div className="row g-4 g-lg-4 justify-content-center">
          {Object.keys(items).length > 0 ?
            items.map((item,key)=>{
                return (
                    <div className="col-lg-4" key={key}>
                        <div className="service-box " data-aos="fade-up"> 
                            <Link to={'/items/'+item.slug} className="service-box-img"> <img src={url+'/storage/'+item.thumbnail}/>
                                <div className="arrow-hover">{item.name}</div>
                            </Link> 
                        </div>
                      </div>
                      );
            })
            :
          <div className="col-lg-12">
            <div className="service-box text-center" data-aos="fade-up"> 
                No Record Found. 
            </div>
          </div>}
          </div>
        </div>
      </section>
    </>
  )
}

export default Energy