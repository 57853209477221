import React, { useEffect, useState, useContext, useRef } from "react"
import { Link } from 'react-router-dom';
//import ReactPaginate from 'react-paginate';
import { capitalize, callService } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_Title, BASE_URL } from '../../constants';
import { CTX } from '../../context';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 9
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Home = (props) => {
  let title = "";
  const dataFetchedRef = useRef(false);
  const [items, setItems] = useState([]);
  const [url, setUrl] = useState('');

  const objContext = useContext(CTX);
  const { setPageLoad, setFooterLink } = objContext;

  useEffect(() => {
    //console.log(" item initi ")
    const asyncFn = async () => {
      //console.log(" item initi  in function ")
      await fetchData();
    };
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    asyncFn();
  }, [!dataFetchedRef.current])//props.match.params.type


  const fetchData = async (queryString = {}) => {
    setPageLoad(true);
    let url = `${API_URL.HOME_API}` + '';
    const response = await callService(url, 'POST', {});
    if (typeof response !== undefined && typeof response.success !== undefined && response.success) {

      console.log(response, response.data.energy_category.length, response.extra.url, " page loaded ");
      setItems(response.data);
      setUrl(response.extra.url)
      setFooterLink({ 'energy': response.data.footer_energy_category, "infra": response.data.footer_infra_category })
    }
    setPageLoad(false);
  }
  const bgImg = BASE_URL + "/img/banner.jpg";
  return (
    <>
      <section className="banner slider-banner" >
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {Object.keys(items).length > 0 && items?.slider && items.slider.length > 0 ?
              items.slider.map((item, key) => {
                return (
                  <div className={key == 0 ? "carousel-item active" : "carousel-item"} key={key}>
                    <img src={url + '/storage/' + item.image} className="d-block w-100" alt={item.heading} style={{ height: '100vh' }} />
                    <div className="carousel-caption" data-aos="fade-up">
                      <h5>{item.heading}</h5>
                    </div>
                  </div>
                );
              })
              :
              <></>
            }
          </div>
          {Object.keys(items).length > 0 && items?.slider && items.slider.length > 0 && <>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
          </>}
        </div>
      </section>
      <section className="service py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 m-auto">
              <div className="title pb-1 pt-2 text-center " data-aos="fade-up">Energy Sector</div>
              <p className="pt-3 pb-4 text-center " data-aos="fade-up">Turkmen Gala ES is an Turkmenistan local company that has successfully contributed the growth of Turkmenistan’s energy sector for more than a decade. </p>
            </div>
          </div>
          <div className="row g-4 g-lg-4 justify-content-center">
            {Object.keys(items).length > 0 && items.energy_category.length > 0 ?
              items.energy_category.map((item, key) => {
                return (
                  <div className="col-lg-4" key={key}>
                    <div className="service-box " data-aos="fade-up">
                      <Link to={'/items/' + item.slug} className="service-box-img"> <img src={url + '/storage/' + item.thumbnail} />
                        <div className="arrow-hover">{item.name}</div>
                      </Link>
                    </div>
                  </div>
                );
              })
              :
              <div className="col-lg-12">
                <div className="service-box text-center" data-aos="fade-up">
                  No Record Found.
                </div>
              </div>}
          </div>
        </div>
      </section>
      <section className="service py-5 bg-warning">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 m-auto">
              <div className="title pb-1 pt-2 text-center text-white" data-aos="fade-up" >Infra Sector</div>
              <p className="pt-3 pb-4 text-center text-white" data-aos="fade-up">Turkmen Gala ES is an Turkmenistan local company that has successfully contributed the growth of Turkmenistan’s energy sector for more than a decade. </p>
            </div>
          </div>
          <div className="row g-4 g-lg-4 justify-content-center">
            {Object.keys(items).length > 0 && items.infra_category.length > 0 ?
              items.infra_category.map((item, key) => {
                return (
                  <div className="col-lg-4" key={key}>
                    <div className="service-box " data-aos="fade-up">
                      <Link to={'/items/' + item.slug} className="service-box-img"> <img src={url + '/storage/' + item.thumbnail} />
                        <div className="arrow-hover">{item.name}</div>
                      </Link>
                    </div>
                  </div>
                );
              })
              :
              <div className="col-lg-12">
                <div className="service-box text-center text-white" data-aos="fade-up">
                  No Record Found.
                </div>
              </div>}
          </div>
        </div>
      </section>

      <section className="who-we-are py-5 my-lg-5">
        <div className="container">

          <div className="row">
            <div className="col-lg-3">
              <h2 className="fw-noraml text-primary" data-aos="fade-up">Who we are</h2>
            </div>
            <div className="col-lg-9" data-aos="fade-up">
              <p> Turkmen Gala is a one of main and decade experienced Turkmenistan’s conglomerate pursuing a highly focused approach of establishing, incorporating, acquiring, and delivering enterprises. Turkmen Gala has enjoyed a track record of consistent entrepreneurship, innovation, performance, reliability and total customer satisfaction.</p>
              <Link to="/who_we_are" className="text-primary fw-bold" data-aos="fade-up">Learn more <img src={BASE_URL + "/img/arrow.svg"} className="ms-2" /></Link>
            </div>
          </div>

          <div className="row pt-lg-5 mt-lg-2">
            <div className="col-lg-12 text-center"><h1 className="fw-light pt-2" data-aos="fade-up">Our Partners</h1></div>
            <div className="col-lg-12" data-aos="fade-up">
              <div className="row our-partners-logo mt-3 justify-content-center">
                {Object.keys(items).length > 0 && items?.partners && items.partners.length > 0 ?
                  <Carousel responsive={responsive} autoPlay={true} infinite={true} containerClass="carousel-container" itemClass="carousel-item-padding-40-px" autoPlaySpeed={2000}>
                    {items.partners.length > 0 &&
                      items.partners.map((item, key) => {
                        return (
                          <div key={key} className="m-3 text-center"><img src={url + '/storage/' + item.logo} width="120" alt={item.name} /> </div>
                        );
                      })}
                  </Carousel>
                  : <div className="col-lg-2" data-aos="fade-up">
                    <div className="service-box text-center" data-aos="fade-up">
                      No Record Found.
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>

          <div className="row pt-lg-5 mt-lg-2">
            <div className="col-lg-3">
              <h1 className="fw-light pt-2" data-aos="fade-up">Our news</h1>
            </div>
            <div className="col-lg-7" data-aos="fade-up">
              <div id="carouselExampleControls2" className="carousel slide logo-news-slider" data-bs-ride="carousel">
                <div className="carousel-inner">
                  {Object.keys(items).length > 0 && items?.news && items.news.length > 0 ?
                    items.news.map((item, key) => {
                      return (<div className={key == 0 ? "carousel-item active" : "carousel-item"} key={key}>
                        <div className="logo-news-box">
                          <div className="logo-news d-flex align-items-center">
                            <div>
                              <img width="80" src={url + '/storage/' + item.thumbnail} />
                            </div>
                            <div className="compnayname ps-4">
                              <h3>{item.name}</h3>
                              {/*<p></p>*/}
                            </div>
                          </div>
                          <p className="logo-news-box-text" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>
                      </div>);
                    })
                    : <></>
                  }
                </div>
                {Object.keys(items).length > 0 && items?.news && items.news.length > 1 &&
                  <>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </>}
              </div>
            </div>
          </div>

          <div className="row pt-lg-5 mt-lg-2">
            <div className="col-lg-3">
              <h1 className="fw-light pt-2" data-aos="fade-up">We comply</h1>
            </div>
            <div className="col-lg-7" data-aos="fade-up">
              <div id="carouselExampleControls3" className="carousel slide logo-news-slider We-complies-slider" data-bs-ride="carousel">
                <div className="carousel-inner">

                  {Object.keys(items).length > 0 && items?.complies && items.complies.length > 0 ?
                    items.complies.map((item, key) => {
                      return (<div className={key == 0 ? "carousel-item active" : "carousel-item"} key={key}>
                        <div className="logo-news-box">
                          <div className="logo-news d-flex align-items-center border-0 justify-content-center">
                            <div>
                              <img width="80" src={url + '/storage/' + item.logo} />
                            </div>
                            <div className="compnayname ps-4">
                              <h3>{item.name}</h3>
                              {/*<p></p>*/}
                            </div>
                          </div>
                        </div>
                      </div>);
                    })
                    : <></>
                  }

                </div>
                {Object.keys(items).length > 0 && items?.complies && items.complies.length > 1 &&
                  <>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls3" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls3" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </>
                }
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Home